import React from 'react';
import { Button, Header, Icon, Menu, Tab } from 'semantic-ui-react';
import CustomTable from '../components/common/CustomTable';
import TechnicianModal from '../components/technician/TechnicianModal';
import { TECHNICIAN_STATUS, TECHNICIAN_STATUS_DISPLAY } from '../constances/models';
import { URL_TECHNICIAN } from '../constances/urls';


export default function TechnicianView() {
  const [target, setTarget] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(TECHNICIAN_STATUS.PENDING_APPROVE);

  const columns = React.useMemo(() => [
    { Header: 'Email', accessor: 'email' },
    { Header: 'รหัสประจำตัวผู้เสียภาษี', accessor: 'id_card' },
    { Header: 'ชื่อ', accessor: 'first_name' },
    { Header: 'นามสกุล', accessor: 'last_name' },
    { Header: '', accessor: 'id', Cell: ({row, value}) => (
      <div style={{ textAlign: 'center' }}>
        <Button
          basic
          color='blue'
          content='รายละเอียด'
          icon='file alternate outline'
          onClick={() => setTarget(row.original)}/>
      </div>
    )}
  ], [])

  const menus = [
    { 
      key: TECHNICIAN_STATUS.PENDING_APPROVE,
      active: activeItem === TECHNICIAN_STATUS.PENDING_APPROVE,
      name: TECHNICIAN_STATUS_DISPLAY[TECHNICIAN_STATUS.PENDING_APPROVE],
      onClick: () => setActiveItem(TECHNICIAN_STATUS.PENDING_APPROVE),
      icon: 'redo'
    },
    { 
      key: TECHNICIAN_STATUS.PENDING_PROFILE_UPDATE,
      active: activeItem === TECHNICIAN_STATUS.PENDING_PROFILE_UPDATE,
      name: TECHNICIAN_STATUS_DISPLAY[TECHNICIAN_STATUS.PENDING_PROFILE_UPDATE],
      onClick: () => setActiveItem(TECHNICIAN_STATUS.PENDING_PROFILE_UPDATE),
      icon: 'edit outline',
    },
    { 
      key: TECHNICIAN_STATUS.APPROVED,
      active: activeItem === TECHNICIAN_STATUS.APPROVED,
      name: TECHNICIAN_STATUS_DISPLAY[TECHNICIAN_STATUS.APPROVED],
      onClick: () => setActiveItem(TECHNICIAN_STATUS.APPROVED),
      icon: 'check'
    },
    { 
      key: TECHNICIAN_STATUS.BLOCKED,
      active: activeItem === TECHNICIAN_STATUS.BLOCKED,
      name: TECHNICIAN_STATUS_DISPLAY[TECHNICIAN_STATUS.BLOCKED],
      onClick: () => setActiveItem(TECHNICIAN_STATUS.BLOCKED),
      icon: 'ban'
    },
  ]

  return (
    <>
      <Menu items={menus} pointing secondary/>
      <TechnicianModal
        target={target}
        open={target != null}
        onClose={() => setTarget(null)}/>
      <CustomTable
        fetchUrl={`${URL_TECHNICIAN.TECHNICIAN}`}
        extraFilterParams={{ status: activeItem }}
        columns={columns}
        showPagination
        showSearch
        showSize/>
    </>
  )
}