export const DefaultServicePaymentModel = {
  id: null,
  transaction: null,
  amount: '',
  customer_detail: '',
  technician_detail: '',
  service_detail: '',
  images: [],
  created: '',
};

export const SERVICE_PAYMENT_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const SERVICE_PAYMENT_TYPE = {
  PAYMENT: 'payment',
  ADVANCE: 'advance'
}