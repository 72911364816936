import React from 'react';
import { Button } from 'semantic-ui-react';


export default function OpenMapButton({ location }) {
  const handleForwardDirection = () => {
		if (!navigator.userAgentData && !navigator.platform) {
			return;
		}
		const platform = navigator.userAgentData ? navigator.userAgentData.platform : navigator.platform;
		if (
			platform.includes("iPhone") || 
			platform.includes("iPad") || 
			platform.includes("iPod")
		) {
			window.open(`maps://www.google.com/?q=${location.latitude},${location.longitude}`)
		} else {
			window.open(`https://maps.google.com/?q=${location.latitude},${location.longitude}`)
		}
	}

  return (
    <Button basic
      size='mini'
      icon='map outline'
      color='blue'
      content='เปิดแผนที่'
      onClick={handleForwardDirection}/> 
  )
}