import React from 'react';
import { Label } from 'semantic-ui-react';
import { TECHNICIAN_TYPE } from '../../constances/models';


export default function TechnicianTypeLabel({ value }) {
  const render = () => {
    switch(value) {
      case 'JP': return <Label basic icon='building' color='purple' content={TECHNICIAN_TYPE.JP}/>;
      case 'OP': return <Label basic icon='user' color='blue' content={TECHNICIAN_TYPE.OP}/>;
      default: return null
    }
  }

  return render()
}