import moment from "moment";

/** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY");
}

export const monthToString = (date) => {
	if (!date) {
		return "";
	}

	return moment(date, 'MM-YYYY').format("MMM YYYY")
}

export const formatComma = (value, enable_negative=false) => {
  if (!value) {
    return 0;
  }
  const isNegative = value.toString().startsWith('-')
  const split_decimal = (+parseFloat(value.toString().replace(',', '').replace('-', ''))).toFixed(2).split('.');
  const number = split_decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+split_decimal[1] ? "." + split_decimal[1] : "");
  return `${isNegative && enable_negative ? '-' : ''}${number}`
}

export const ellipsisString = (value, threshold_length=15) => {
  if (!value) {
    return '';
  }
  
  return `${value.toString().substring(0, threshold_length)}${value.toString().length > threshold_length ? '...' : ''}`
}

export const convertNullToEmpty = (obj) => {
	return Object.keys(obj).reduce((o, current) => {
		if(obj[current] != null && obj[current] != undefined && obj[current] != '') {
			return { ...o, [current]: obj[current] };
		}
		return { ...o, [current]: '' };
	}, {})
}

export const imageToGrayScale = (file, max_width) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (readerEvent) {
			let image = new Image();
			image.onload = function (imageEvent) {

				// Resize the image
				let canvas = document.createElement('canvas'),
					width = image.width,
					height = image.height;

				if (width > max_width) {
					height = height * (max_width / width);
					width = max_width;
				}

				canvas.width = width;
				canvas.height = height;
				canvas.getContext('2d').drawImage(image, 0, 0, width, height);
				let imgPixels = canvas.getContext('2d').getImageData(0, 0, width, height);
				for(let y = 0; y < imgPixels.height; y++) {
					for(let x = 0; x < imgPixels.width; x++) {
						let i = (y * 4) * imgPixels.width + x * 4;
						let avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
						imgPixels.data[i] = avg; 
						imgPixels.data[i + 1] = avg; 
						imgPixels.data[i + 2] = avg;
					}
				}
				canvas.getContext('2d').putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);

				const dataUrl = canvas.toDataURL('image/png');
				resolve(dataUrl);
			}
			image.src = readerEvent.target.result;
		}
		reader.readAsDataURL(file);
	});
}