export const DefaultCoinRefillRequestModel = {
  id: null,
  technician: null,
  coin: '',
  price: '',
  attachment: null,
  note: '',
  is_approved: null,
  technician_name: '',
  technician_id_card: '',
  created: '',
};

export const COIN_REFILL_REQUEST_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};