import React from 'react';
import { GET } from '../frameworks/HttpClient';


// usage ==> const { response, isLoading, errorMessages, fetchData } = useListItem({url: '/api/items/'});
export default function useListItem ({url, params, manual = false, usePagination = false}) {
  const defaultResponse = usePagination ? 
  { 
    links: {
      next: null,
      previous: null,
    },
    total: 0,
    pages: 0,
    results: [] 
  } : []

  const [response, setResponse] = React.useState(defaultResponse);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  React.useEffect(() => {
    if(!manual) {
      fetchData()
    }
  },[]);

  const fetchData = async (additional_params) => {
    setResponse(defaultResponse)
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await GET(url, { ...params, ...additional_params });
      setResponse(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  };

  return { response, isLoading, errorMessages, fetchData };
}