import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from "react-router-dom";
import { MediaContextProvider } from "./AppMedia";
import CustomRoutes from './routes/CustomRoutes';
import { ToastContainer } from 'react-toastify';
import CustomLoading from './components/common/CustomLoading';


function App() {

  return (
    <MediaContextProvider>
      <React.Suspense fallback={
        <CustomLoading active={true}/>
      }>
        <ToastContainer
          position='top-center'
          autoClose='3000'
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/>
          <Router>
            <CustomRoutes/>
          </Router>
      </React.Suspense>
    </MediaContextProvider>
  );
}

export default App;
