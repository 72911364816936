import React from 'react';
import { Button, Form, Grid, Header, Icon, Image, Label, List, Message, Modal } from 'semantic-ui-react';
import { DefaultCoinRefillRequestModel } from '../../constances/models';
import * as _ from 'lodash';
import { POST } from '../../frameworks/HttpClient';
import { toast } from 'react-toastify';
import { dateTimeToString } from '../../frameworks/Util';
import { URL_WALLET } from '../../constances/urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmModal from '../common/ConfirmModal';
import CustomErrorMessage from '../common/CustomErrorMessage';
import DetailList from '../common/DetailList';


export default function CoinRefillRequestModal(props) {
  const {
    open,
    onClose,
    target
  } = props;

  const [detail, setDetail] = React.useState(DefaultCoinRefillRequestModel);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const [isRejectConfirmVisible, setIsRejectConfirmVisible] = React.useState(false);

  const rejectForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      note: Yup.string().required(),
    }),
    initialValues: { amount: 0 },
    onSubmit: ({ note }) => doAction('reject', {note})
  })

  const closeAllModal = () => {
    setIsRejectConfirmVisible(false);  
    onClose();
  }

  const doAction = async (action, data) => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await POST(`${URL_WALLET.COIN_REFILL_REQUEST}${detail.id}/${action}/`, {...data});
      toast.success(_.get(response.data, 'detail', 'สำเร็จ'));
      closeAllModal();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null)
    }
  }, [open])

  React.useEffect(() => {
    if(target) {
      setDetail(target)
    }
  }, [target])

  return (
    <>
      <ConfirmModal
        open={isRejectConfirmVisible}
        onClose={() => setIsRejectConfirmVisible(false)}
        title='ปฏิเสธเติมเหรียญช่าง'
        form={rejectForm}
        fields={[{ name: 'note', icon: 'edit', label: 'หมายเหตุ', type: 'string' }]}
        confirmText='ยืนยันการปฏิเสธ'
        confirmIcon='ban'
        confirmColor='red'
        isLoading={isLoading}
        errorMessages={errorMessages}/>

      <Modal
        centered={false}
        open={open}
        onClose={onClose}>
        <Modal.Header>
          <Icon color='teal' name='dot circle'/>
          รายละเอียดการเติมเหรียญช่าง
        </Modal.Header>
        <Modal.Content image>
          <Image wrapped style={{ maxWidth: 300 }} src={detail.image}/>
          <Modal.Description>
            <CustomErrorMessage value={errorMessages}/>
            <DetailList
              menus={[
                { icon: 'calendar', title: 'วันที่', content: dateTimeToString(detail.created) },
                { icon: 'user', title: 'ชื่อลูกค้า', content: detail.technician_name },
                { icon: 'address card', title: 'รหัสประจำตัวผู้เสียภาษี', content: detail.technician_id_card },
                { icon: 'exchange', title : 'อัตราแลก', content: (
                  <div>
                    <Icon name='money'/> {detail.price} บาท <Icon name='arrow right'/>  <Icon name='dot circle'/> {detail.coin} เหรียญ
                  </div> 
                )},
                { icon: 'edit', title: 'หมายเหตุ', content: detail.note },
              ]}/>
              
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={isLoading}
            onClick={() => setIsRejectConfirmVisible(true)}
            negative
            icon='ban'
            content='ปฏิเสธ'/>
          <Button
            loading={isLoading}
            onClick={() => doAction('approve')}
            positive
            icon='check'
            content='อนุมัติ'/>
        </Modal.Actions>
      </Modal>
    </>
  )
}