import React from 'react';
import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import * as _ from 'lodash';
import propTypes from 'prop-types';
import CustomErrorMessage from './CustomErrorMessage';


const ConfirmModal = (props) => {
  const {
    open,
    onClose,
    title,
    description,
    form,
    fields,
    onConfirm,
    confirmText,
    confirmIcon,
    confirmColor,
    isLoading,
    errorMessages
  } = props;

  return (
    <Modal
      centered={false}
      open={open} 
      onClose={onClose}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <CustomErrorMessage value={errorMessages}/>
        {description}
        {form && (
          <Form>
            {fields.map(field => (
              <Form.Input
                icon={_.get(field, 'icon', null)}
                iconPosition='left'
                label={_.get(field, 'label', 'หมายเหตุ')}
                placeholder={_.get(field, 'label', 'หมายเหตุ')}
                name={field.name}
                type={_.get(field, 'type', 'string')}
                value={_.get(form.values, field.name, '')}
                error={_.get(form.errors, field.name, null)}
                onChange={form.handleChange}/>
            ))}
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={isLoading}
          onClick={onClose}
          icon='cancel'
          content='ยกเลิก'/>
        <Button
          loading={isLoading}
          onClick={(...params) => form ? form.handleSubmit(params) : onConfirm(params)}
          color={confirmColor}
          icon={confirmIcon}
          content={confirmText}/>
      </Modal.Actions>
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  open: false,
  onClose: () => {},
  title: '',
  description: '',
  form: null,
  fields: [],
  onConfirm: () => {},
  confirmText: '',
  confirmIcon: '',
  confirmColor: '',
  isLoading: false,
  errorMessages: null,
}

ConfirmModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
  description: propTypes.string,
  form: propTypes.object,
  fields: propTypes.array,
  onConfirm: propTypes.func,
  confirmText: propTypes.string,
  confirmIcon: propTypes.string,
  confirmColor: propTypes.string,
  isLoading: propTypes.bool,
  errorMessages: propTypes.string,
}

export default ConfirmModal;