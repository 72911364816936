import React from 'react';
import { URL_SERVICE } from '../constances/urls';
import CustomTable from '../components/common/CustomTable';
import { dateTimeToString, formatComma } from '../frameworks/Util';
import { Button } from 'semantic-ui-react';
import TechnicianPaymentModal from '../components/service/PaymentTechnicianModal';


export default function PayingTechnicianView() {
  const [target, setTarget] = React.useState(null);

  const columns = React.useMemo(() => [
    { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
    { Header: 'ประเภท', accessor: 'type_display' },
    { Header: 'จำนวนเงิน', accessor: 'amount', Cell: ({ row, value }) => (<div>{formatComma(value * (1 - 0.1))}</div>) },
    { Header: 'ลูกค้า', accessor: 'customer_detail' },
    { Header: 'ช่าง', accessor: 'technician_detail' },
    { Header: 'บริการ', accessor: 'service_detail' },
    { Header: '', accessor: 'id', Cell: ({row, value}) => (
      <div style={{ textAlign: 'center' }}>
        <Button
          basic
          color='blue'
          content='รายละเอียด'
          icon='file alternate outline'
          onClick={() => setTarget(row.original)}/>
      </div>
    )}
  ], []);

  return (
    <div>
      <CustomTable
        fetchUrl={URL_SERVICE.PAYMENT_TECHNICIAN}
        extraFilterParams={{ is_approved: true, is_paid: false }}
        columns={columns}
        showPagination
        showSize/>
      <TechnicianPaymentModal
        open={target != null}
        onClose={() => setTarget(null)}
        target={target}/>
    </div>
  )
}