import React from 'react';
import { Button } from 'semantic-ui-react';
import CustomTable from '../components/common/CustomTable';
import CoinRefillRequestModal from '../components/wallet/CoinRefillRequestModal';
import { COIN_REFILL_REQUEST_STATUS } from '../constances/models';
import { URL_WALLET } from '../constances/urls';
import { dateTimeToString } from '../frameworks/Util';


export default function CoinView(props) {
  const [target, setTarget] = React.useState(null);

  const columns = React.useMemo(() => [
    { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
    { Header: 'ชื่อช่าง', accessor: 'technician_name' },
    { Header: 'รหัสประจำตัวผู้เสียภาษี', accessor: 'technician_id_card' },
    { Header: '', accessor: 'id', Cell: ({row, value}) => (
      <div style={{ textAlign: 'center' }}>
        <Button
          basic
          color='blue'
          content='รายละเอียด'
          icon='file alternate outline'
          onClick={() => setTarget(row.original)}/>
      </div>
    )}
  ], [])

  return (
    <>
      <CoinRefillRequestModal
        open={target != null}
        onClose={() => setTarget(null)}
        target={target}/>
      <CustomTable
        fetchUrl={`${URL_WALLET.COIN_REFILL_REQUEST}`}
        extraFilterParams={{ status: COIN_REFILL_REQUEST_STATUS.PENDING }}
        columns={columns}
        showPagination
        showSearch
        showSize/>
    </>
  )
}