import React from 'react';
import { Button, Divider, Header, Icon, Image, List, Message, Modal } from 'semantic-ui-react';
import { BANK_CODE, defaultTechnicianModel, TECHNICIAN_STATUS, TECHNICIAN_STATUS_ACTION } from '../../constances/models';
import defaultProfile from '../../assets/default_profile.png';
import * as _ from 'lodash';
import TechnicianTypeLabel from './TechnicianTypeLabel';
import OpenMapButton from '../common/OpenMapButton';
import { POST } from '../../frameworks/HttpClient';
import { URL_TECHNICIAN } from '../../constances/urls';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmModal from '../common/ConfirmModal';
import CustomErrorMessage from '../common/CustomErrorMessage';
import DetailList from '../common/DetailList';


export default function TechnicianModal(props) {
  const {
    open,
    onClose,
    target
  } = props;

  const [detail, setDetail] = React.useState(defaultTechnicianModel);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const [isRejectConfirmVisible, setIsRejectConfirmVisible] = React.useState(false);
  const [isBlockConfirmVisible, setIsBlockConfirmVisible] = React.useState(false);

  const rejectForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      note: Yup.string().required(),
    }),
    initialValues: { note: '' },
    onSubmit: ({ note }) => doAction({ action: TECHNICIAN_STATUS_ACTION.REJECT, note })
  })
  const blockForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      note: Yup.string().required(),
    }),
    initialValues: { note: '' },
    onSubmit: ({ note }) => doAction({ action: TECHNICIAN_STATUS_ACTION.BLOCK, note })
  })

  const closeAllModal = () => {
    setIsRejectConfirmVisible(false);
    setIsBlockConfirmVisible(false);
    onClose();
  }

  const doAction = async (data) => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await POST(`${URL_TECHNICIAN.TECHNICIAN}${detail.id}/change_status/`, {...data});
      toast.success(_.get(response.data, 'detail', 'สำเร็จ'));
      closeAllModal();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null)
    }
  }, [open])

  React.useEffect(() => {
    if(target) {
      console.log(target)
      setDetail(target)
    }
  }, [target])

  return (
    <>
      <ConfirmModal
        open={isRejectConfirmVisible}
        onClose={() => setIsRejectConfirmVisible(false)}
        title='ยืนยันการปฏิเสธ'
        form={rejectForm}
        fields={[{ name: 'note', icon: 'edit', label: 'หมายเหตุ', type: 'string' }]}
        confirmText='ยืนยันการปฏิเสธ'
        confirmIcon='cancel'
        confirmColor='red'
        isLoading={isLoading}
        errorMessages={errorMessages}/>

      <ConfirmModal
        open={isBlockConfirmVisible}
        onClose={() => setIsBlockConfirmVisible(false)}
        title='ยืนยันระงับบัญชีช่าง'
        form={blockForm}
        fields={[{ name: 'note', icon: 'edit', label: 'หมายเหตุ', type: 'string' }]}
        confirmText='ยืนยันการระงับบัญชี'
        confirmIcon='ban'
        confirmColor='red'
        isLoading={isLoading}
        errorMessages={errorMessages}/>

      <Modal
        centered={false}
        open={open}
        onClose={onClose}>
        <Modal.Header>
          <Icon color='teal' name='user'/>
          รายละเอียดช่าง
        </Modal.Header>
        <Modal.Content image>
          <Image wrapped style={{ width: 300 }} src={ detail.profile_picture ? detail.profile_picture : defaultProfile }/>
          <Modal.Description>
            <CustomErrorMessage value={errorMessages}/>
            {detail.status_note && (
              <Message warning>
                <Message.Header>หมายเหตุ</Message.Header>
                {detail.status_note}
              </Message>
            )}
            <Header as='h2'>
              {detail.display_name ? detail.display_name : detail.last_name}
              <TechnicianTypeLabel value={detail.technician_type}/>
              <Header.Subheader>{detail.email}</Header.Subheader>
            </Header>
            <DetailList
              menus={[
                { icon: 'id card', title: 'เลขบัตรประชาชน/เลขที่ผู้เสียภาษีอากร', content: detail.id_card },
                { icon: 'phone', title: 'เบอร์โทรศัพท์', content: detail.telephone },
                { icon: 'credit card', title: 'บัญชีธนาคาร', content: `${_.get(BANK_CODE, detail.bank, '')} ${detail.bank_account}` },
                { icon: 'building', title: 'ที่อยู่', content: detail.full_address },
                { icon: 'users', title: 'อาชีพ', content: detail.roles.length > 0 ? detail.roles.map(role => role.name).join(', ') : '-' },
                { icon: 'pin', title: 'พื้นที่ให้บริการ', content: <OpenMapButton location={detail.service_location}/> },
              ]}/>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          { detail.status === TECHNICIAN_STATUS.BLOCKED ? (
              <Button
              loading={isLoading}
              onClick={() => doAction({action: TECHNICIAN_STATUS_ACTION.UNBLOCK})}
              basic
              color='teal'
              icon='undo'
              content='เลิกระงับการใช้งาน'/>
          ) : (
            <>
              { detail.status === TECHNICIAN_STATUS.APPROVED ? (
                <>
                  <Button
                    loading={isLoading}
                    onClick={onClose}
                    content='ปิด'/>
                </>
              ) : (
                <>
                  <Button
                    loading={isLoading}
                    onClick={() => setIsRejectConfirmVisible(true)}
                    negative
                    icon='cancel'
                    content='ปฏิเสธ'/>
                  <Button
                    loading={isLoading}
                    onClick={() => doAction({action: TECHNICIAN_STATUS_ACTION.APPROVE})}
                    positive
                    icon='check'
                    content='อนุมัติ'/>
                </>
              )}
              <Button
                loading={isLoading}
                onClick={() => setIsBlockConfirmVisible(true)}
                floated='left'
                icon='ban'
                color='red'
                basic
                content='ระงับการใช้งาน'/>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  )
}