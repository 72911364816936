export const URL_AUTH = {
	API_TOKEN_AUTH: "/api/users/api-token-auth/",
};

export const URL_COMMON = {
	PROVINCE: "/api/common/province/",
	DISTRICT: "/api/common/district/",
	SUB_DISTRICT: "/api/common/sub-district/",
	ADMIN_MENU_NOTIFICATION: "api/common/admin-menu-notification/",
}

export const URL_SERVICE = {
	SERVICE_PAYMENT: "/api/service/service-payment/",
	PAYMENT_TECHNICIAN: "/api/service/payment-technician/",
}

export const URL_TECHNICIAN = {
	TECHNICIAN: "/api/technician/technician/"
}

export const URL_WALLET = {
	COIN_REFILL_REQUEST: "/api/wallet/coin-refill-request/"
}