import React from 'react';
import CustomTable from '../components/common/CustomTable';
import { dateTimeToString } from '../frameworks/Util';
import { Button } from 'semantic-ui-react';
import { URL_SERVICE } from '../constances/urls';
import { SERVICE_PAYMENT_STATUS, SERVICE_PAYMENT_TYPE } from '../constances/models';
import PaymentModal from '../components/service/PaymentModal';


export default function PaymentView() {
  const [target, setTarget] = React.useState(null);

  const columns = React.useMemo(() => [
    { Header: 'วันที่', accessor: 'created', Cell: ({value}) => dateTimeToString(value) },
    { Header: 'จำนวนเงิน', accessor: 'amount' },
    { Header: 'ลูกค้า', accessor: 'customer_detail' },
    { Header: 'ช่าง', accessor: 'technician_detail' },
    { Header: 'บริการ', accessor: 'service_detail' },
    { Header: '', accessor: 'id', Cell: ({row, value}) => (
      <div style={{ textAlign: 'center' }}>
        <Button
          basic
          color='blue'
          content='รายละเอียด'
          icon='file alternate outline'
          onClick={() => setTarget(row.original)}/>
      </div>
    )}
  ], [])

  return (
    <>
      <PaymentModal
        open={target != null}
        onClose={() => setTarget(null)}
        target={target}/>
      <CustomTable
        fetchUrl={URL_SERVICE.SERVICE_PAYMENT}
        extraFilterParams={{ status: SERVICE_PAYMENT_STATUS.PENDING, type: SERVICE_PAYMENT_TYPE.PAYMENT, is_paid: false }}
        columns={columns}
        showPagination
        showSize/>
    </>
  )
}