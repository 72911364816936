import React from 'react';
import { Button, Form, Grid, Header, Icon, Image, Label, List, Message, Modal } from 'semantic-ui-react';
import { DefaultServicePaymentModel } from '../../constances/models';
import * as _ from 'lodash';
import { POST } from '../../frameworks/HttpClient';
import { toast } from 'react-toastify';
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import { URL_SERVICE, URL_WALLET } from '../../constances/urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ConfirmModal from '../common/ConfirmModal';
import CustomErrorMessage from '../common/CustomErrorMessage';
import DetailList from '../common/DetailList';


export default function PaymentModal(props) {
  const {
    open,
    onClose,
    target
  } = props;

  const [detail, setDetail] = React.useState(DefaultServicePaymentModel);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const [isRejectConfirmVisible, setIsRejectConfirmVisible] = React.useState(false);

  const closeAllModal = () => {
    setIsRejectConfirmVisible(false);  
    onClose();
}

  const doAction = async (action, data) => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await POST(`${URL_SERVICE.SERVICE_PAYMENT}${detail.id}/${action}/`, {...data});
      toast.success(_.get(response.data, 'detail', 'สำเร็จ'));
      closeAllModal();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null)
    }
  }, [open])

  React.useEffect(() => {
    if(target) {
      setDetail(target)
    }
  }, [target])

  return (
    <>
      <ConfirmModal
        open={isRejectConfirmVisible}
        onClose={() => setIsRejectConfirmVisible(false)}
        title='ปฏิเสธการชำระเงิน'
        description='ต้องการปฏิเสธการชำระเงินนี้หรือไม่?'
        onConfirm={() => doAction('admin_reject')}
        confirmText='ยืนยันการปฏิเสธ'
        confirmIcon='ban'
        confirmColor='red'
        isLoading={isLoading}
        errorMessages={errorMessages}/>

      <Modal
        centered={false}
        open={open}
        onClose={onClose}>
        <Modal.Header>
          <Icon color='teal' name='dollar sign'/>
          รายละเอียดการชำระเงิน
        </Modal.Header>
        <Modal.Content image>
          <CustomErrorMessage value={errorMessages}/>
          <DetailList
            menus={[
              { icon: 'calendar', title: 'วันที่', content: dateTimeToString(detail.created) },
              { icon: 'dollar sign', title: 'จำนวนเงิน', content: `${formatComma(detail.amount)} บาท` },
              { icon: 'user outline', title: 'ลูกค้า', content: detail.customer_detail },
              { icon: 'user', title: 'ช่าง', content: detail.technician_detail },
              { icon: 'file alternate outline', title: 'บริการ', content: detail.service_detail },
              { icon: 'edit', title: 'หมายเหตุ', content: detail.note },
              { icon: 'images', title: 'ภาพหลักฐาน', content: (
                <Image wrapped style={{ maxWidth: 300 }} src={detail.image}/>

                // <Grid columns='3' stackable>
                //   { detail.images.map(image => (
                //     <Grid.Column><Image src={image.image}/></Grid.Column>
                //   )) }
                // </Grid>
              )}
            ]}/>
              
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={isLoading}
            onClick={() => setIsRejectConfirmVisible(true)}
            negative
            icon='ban'
            content='ปฏิเสธ'/>
          <Button
            loading={isLoading}
            onClick={() => doAction('admin_approve')}
            positive
            icon='check'
            content='อนุมัติ'/>
        </Modal.Actions>
      </Modal>
    </>
  )
}