import React from 'react';
import { useTable } from 'react-table/dist/react-table.development';
import { Button, Checkbox, Dropdown, Form, Grid, Pagination, Segment, Table } from 'semantic-ui-react';
import propTypes from 'prop-types';
import useItemList from '../../hooks/useItemList';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomErrorMessage from './CustomErrorMessage';
import CustomLoading from './CustomLoading';


const PAGE_SIZE = 10;

const CustomTable = React.forwardRef((props, ref) => {
  const {
    columns,
    fetchUrl,
    manual,

    // pagination props
    showPagination,

    // filter props
    showSearch,
    showSize,
    extraFilter,
    extraFilterParams,

    // select props
    showSelect,
    extraActions,
    updateSelectedList,

    // handling parent state
    setIsEmpty
  } = props;

  // filter
  const [searchField, setSearchField] = React.useState(null)
  const [searchValue, setSearchValue] = React.useState(null);

  // pagination
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);

  const { size } = useWindowDimensions();

  const initialRef = React.useRef(false);
  
  const { response, isLoading, errorMessages, fetchData } = useItemList({
    url: fetchUrl,
    params: { ...extraFilterParams },
    manual: manual,
    usePagination: showPagination,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    dispatch,
    state,
  } = useTable({
    columns,
    data: response.results,
    initialState: {
      selectedList: []
    },
    // handling selection reducer
    stateReducer: (newState, action, prevState) => {
      switch (action.type) {
        case 'all':
          return {
            ...newState,
            selectedList: [...newState.selectedList, ...response.results.map(e => e.id)]
          }
        case 'add':
          return { 
            ...newState, 
            selectedList: [...newState.selectedList, action.selectedItem]
          }
        case 'remove':
          return { 
            ...newState, 
            selectedList: [
              ...newState.selectedList.filter(e => e != action.selectedItem)
            ]
          }
        case 'reset':
          return {
            ...newState,
            selectedList: []
          }
        default:
          return newState
      }
    }
  },
  hooks => {
    if (showSelect) {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ state, data }) => (
            <Checkbox
              checked={isSelectedAllInPage(state.selectedList, data)}
              onChange={() => {
                if(isSelectedAllInPage(state.selectedList, data)) {
                  dispatch({ type: 'reset' })
                } else {
                  dispatch({ type: 'all' })
                }
              }}
            />
          ),
          Cell: ({ state, row }) => (
            <Checkbox
              checked={state.selectedList.includes(row.original.id)}
              onChange={() => {
                if (state.selectedList.includes(row.original.id)) {
                  // do remove
                  dispatch({ selectedItem: row.original.id, type: 'remove' })
                } else {
                  // do add
                  dispatch({ selectedItem: row.original.id, type: 'add' })
                }
              }}
            />
          )
        },
        ...columns,
      ])
    }
  });

  const isSelectedAllInPage = (selectedList, data) => {
    const count = data.reduce((p, c) => p + (selectedList.includes(c.id) ? 1 : 0), 0);
    return count === data.length;
  }

  const handleSearch = () => {
    setPage(1);
    setPageSize(PAGE_SIZE);
    setSearchValue(searchField);
    fetchData({ page: 1, page_size: PAGE_SIZE, search: searchField });
  }

  const handlePageSizeChange = (pageSize) => {
    setPage(1);
    setPageSize(pageSize);
    setSearchField(searchValue)
    fetchData({ page: 1, page_size: pageSize, search: searchValue });
  }

  const handlePageChange = (page) => {
    setPage(page);
    setSearchField(searchValue)
    fetchData({ page, page_size: pageSize, search: searchValue });
  }

  React.useImperativeHandle(ref, () => ({
    refetch: () => fetchData(),
	}));

  React.useEffect(() => {
    if(!initialRef.current) {
      initialRef.current = true
      return;
    }
    fetchData();
  }, [fetchUrl, extraFilterParams])

  const renderActionHeader = () => {
    if (
      extraActions ||
      showSearch ||
      showSize ||
      extraFilter
    ) {
      return (
        <Form>
          <Grid columns='3'>
            { extraActions &&
              <Grid.Column floated='right' width='16'>
                {extraActions}
              </Grid.Column>
            }
            <Grid.Column floated='left' width='12'>
              <Form.Group inline>
                {extraFilter}
                {showSearch && 
                  <Form.Input
                    name='search'
                    label='ค้นหา'
                    placeholder='ค้นหา...'
                    value={searchField}
                    onChange={e => setSearchField(e.target.value)}/>
                }
                { (
                    extraActions ||
                    showSearch ||
                    extraFilter
                  ) &&
                  <Button color='blue' icon='search' onClick={handleSearch}/>
                }
              </Form.Group>
            </Grid.Column>
            {
              showSize &&
              <Grid.Column floated='right' width='4'>
                <Form.Group inline>
                  <label>แสดง</label>
                  <Dropdown
                    fluid
                    selection
                    selectOnBlur={false}
                    value={pageSize}
                    options={[
                      {key: '10', text: `10${(size == 'md' || size == 'sm') ? '' : ' แถว'}`, value : 10},
                      {key: '25', text: `25${(size == 'md' || size == 'sm') ? '' : ' แถว'}`, value : 25},
                      {key: '50', text: `50${(size == 'md' || size == 'sm') ? '' : ' แถว'}`, value : 50},
                    ]}
                    onChange={(_, data) => handlePageSizeChange(data.value)}
                  />
                </Form.Group>
              </Grid.Column>
            }
          </Grid>
        </Form>
      )
    }
    return null;
  }
  
  return (
    <Segment basic style={{ textAlign: 'center', padding: 0 }}>
      <CustomLoading active={isLoading}/>
      <CustomErrorMessage value={errorMessages}/>
      { renderActionHeader()}
      <Table {...getTableProps()} unstackable>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
        {response.total === 0 && !isLoading &&
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='100'>
                {/* show just a table footer */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
      { showPagination && 
        <Pagination
          activePage={page}
          totalPages={response.pages}
          onPageChange={(_, data) => handlePageChange(data.activePage)}
        />
      }
    </Segment>
  )
});

CustomTable.defaultProps = {
  columns: [],
  fetchUrl: '',
  manual: false,
  showPagination: false,
  extraFilter: null,
  extraFilterParams: {},
  showSelect: false,
  extraActions: null,
  updateSelectedList: () => null,
  setIsEmpty: () => null
}

CustomTable.propTypes = {
  columns: propTypes.array,
  fetchUrl: propTypes.string,
  manual: propTypes.bool,
  showPagination: propTypes.bool,
  extraFilter: propTypes.array,
  extraFilterParams: propTypes.object,
  showSelect: propTypes.bool,
  extraActions: propTypes.object,
  updateSelectedList: propTypes.func,
  setIsEmpty: () => null
}

export default React.memo(CustomTable);