import { useFormik } from "formik";
import React from "react";
import { Form, Grid, Message, Segment, Image, Button, Header } from 'semantic-ui-react'
import logo from '../assets/cealect_logo.png';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { POST } from "../frameworks/HttpClient";
import { URL_AUTH } from "../constances/urls";
import Cookies from "js-cookie";
import { PATH } from "../routes/CustomRoutes";
import CustomInputField from "../components/common/CustomInputField";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Media } from "../AppMedia";
import CustomErrorMessage from "../components/common/CustomErrorMessage";


export default function LoginScreen() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { size } = useWindowDimensions();
  const [toggleShowPassword, setToggleShowPassword] = React.useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('กรุณากรอกข้อมูล').email('กรอกอีเมลล์ให้ถูกต้อง'),
      password: Yup.string().required('กรุณากรอกข้อมูล'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await POST(URL_AUTH.API_TOKEN_AUTH, values)
        Cookies.set('token', response.data.token, { expires: 30 })
        const userData = response.data.user
        if(userData.user_type_display != 'User') {
          setErrorMessages('Following user is not allowed');
          setIsLoading(false);
          return 
        }
        Cookies.set('user', JSON.stringify(userData), { expires: 30 })
        navigate(PATH.MAIN)          
      } catch(error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  });

  React.useEffect(() => {
    setErrorMessages(null);
  }, [formik.values])

  return (
		<div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 55%;
        }
      `}
      </style>
			<Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
				<Grid.Column style={{ maxWidth: size === 'sm' ? '350px' : '450px' }}>
          <Media at='sm'>
            <Image src={logo} size='tiny' centered/>
            <Header as='h2' style={{ color: '#008ca0' }}>
              Cealect Management System
            </Header>
          </Media>
          <Media greaterThan='sm'>
            <Header as='h2' style={{ color: '#008ca0' }}>
              <Image src={logo}/>
              Cealect Management System
            </Header>
          </Media>
          <Segment>
            <CustomErrorMessage value={errorMessages}/>
            <Form>
              <CustomInputField
                isForm
                name='email'
                placeholder='email'
                icon='mail'
                iconPosition='left'
                values={formik.values.email}
                error={formik.errors.email}
                onChange={formik.handleChange}/>
							<Form.Input
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='password'
                action={
                  <Button
                    basic
                    type='button'
                    icon={toggleShowPassword ? 'eye' : 'eye slash'}
                    onClick={() => setToggleShowPassword(prevState => !prevState)}/>
                }
                actionPosition='right'
                type={toggleShowPassword ? 'text' : 'password'}
                value={formik.values.password}
                error={formik.errors.password}
								onChange={formik.handleChange}
							/>
              <Button
                fluid
                type='submit'
                content='login'
                color='teal'
                icon='sign-in'
                size='large'
                disabled={!formik.values.email || !formik.values.password}
                loading={isLoading}
                onClick={formik.handleSubmit}/>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}
