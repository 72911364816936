import React from 'react';
import { List } from 'semantic-ui-react';


export default function DetailList({menus}) {
  return (
    <List size='large' relaxed>
      { menus.map(menu => (
        <List.Item>
          { menu.icon && <List.Icon name={menu.icon}/> }
          <List.Content>
            <List.Header>{menu.title}</List.Header>
            <List.Description>{menu.content}</List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  )
}