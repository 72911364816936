import React from "react";
import Cookies from 'js-cookie'
import { Navigate, Outlet } from "react-router-dom";
import { PATH } from "./CustomRoutes";


export default function PrivateOutlet() {
  const auth = Cookies.get('token') != null;

  return auth ? <Outlet /> : <Navigate to={PATH.LOGIN} />;
}