import React from 'react';
import { Form, Input } from 'semantic-ui-react';


export default function CustomInputField(props) {

  const { isForm, ...elementProps } = props;

  return isForm ? ( 
    <Form.Input {...elementProps} onFocus={(event) => event.target.select()}/>
  ) : <Input {...elementProps} onFocus={(event) => event.target.select()}/> ;
}