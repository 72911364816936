import React from 'react';
import { Routes, Route } from "react-router-dom";
import LoginScreen from '../screens/LoginScreen';
import MainScreen from '../screens/MainScreen';
import PrivateOutlet from './PrivateOutlet';


export const PATH = {
  LOGIN: "/login",
  MAIN: "/",
  TEST: "/test",
}

export default function CustomRoutes(){
  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />
      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route path='' element={<MainScreen/>} />
      </Route> 
    </Routes>
  )
}
