export const defaultTechnicianModel = {
  id: null,
  display_name: '',
  user_type_display: '',
  roles: [],
  badges: [],
  coin: '',
  status: '',
  status_display: '',
  status_note: '',
  service_location: {
    latitude: 0,
    longitude: 0
  },
  district: null,
  province: null,
  zip_code: '',
  full_address: '',
  last_login: null,
  first_name: '',
  last_name: '',
  is_active: true,
  date_joined: null,
  created: null,
  updated: null,
  email: '',
  telephone: '',
  is_telephone_verified: true,
  is_email_verified: true,
  technician_type: '',
  id_card: '',
  bank: '',
  bank_account: '',
  address_detail: '',
  profile_picture: null,
  average_feedback: '',
  average_feedback_courtesy: '',
  average_feedback_readiness: '',
  average_feedback_outcome: '',
  average_feedback_duration: '',
  average_feedback_impressive: '',
  is_sponsored: false,
  sub_district: null,
};

export const TECHNICIAN_TYPE = {
  'JP': 'นิติบุคคล',
  'OP': 'บุคลลธรรมดา'
}

export const BANK_CODE = {
  '006': 'ธนาคารกรุงไทย',
  '002': 'ธนาคารกรุงเทพ',
  '014': 'ธนาคารไทยพาณิชย์',
  '004': 'ธนาคารกสิกรไทย',
  '025': 'ธนาคารกรุงศรีอยุธยา',
  '011': 'ธนาคารทหารไทย',
  '065': 'ธนาคารธนชาต',
  '030': 'ธนาคารออมสิน',
  '034': 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
  '069': 'ธนาคารเกียรตินาคิน',
  '022': 'ธนาคารซีไอเอ็มบี',
  '033': 'ธนาคารอาคารสงเคราะห์',
  '073': 'ธนาคารแลนด์แอนด์เฮ้าส์',
  '066': 'ธนาคารอิสลาม',
  '024': 'ธนาคารยูโอบี',
  '067': 'ธนาคารทิสโก้',
  '035': 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
  '070': 'ธนาคารไอซีบีซี (ไทย)',
  '071': 'ธนาคารไทยเครดิตเพื่อรายย่อย',
  '098': 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
}

export const TECHNICIAN_STATUS = {
  INITIAL_STATE: 'initial_state',
  PENDING_APPROVE: 'pending_approve',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  BLOCKED: 'blocked',
  PENDING_PROFILE_UPDATE: 'pending_profile_update',
}

export const TECHNICIAN_STATUS_DISPLAY = {
  'initial_state': 'เริ่มต้นการทำงาน',
  'pending_approve': 'รอการยืนยัน',
  'approved': 'ยืนยันแล้ว',
  'rejected': 'ปฏิเสธ',
  'blocked': 'ถูกระงับการใช้งาน',
  'pending_profile_update': 'รอการอัพเดตโปรไฟล์',
}

export const TECHNICIAN_STATUS_ACTION = {
  APPROVE: 'approve',
  REJECT: 'reject',
  BLOCK: 'block',
  UNBLOCK: 'unblock'
}