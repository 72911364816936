import React from 'react';
import { Confirm, Grid, Header, Icon, Image, Label, Menu, Sidebar } from 'semantic-ui-react';
import { Media } from '../../AppMedia';
import logo from '../../assets/cealect_logo.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PATH } from '../../routes/CustomRoutes';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as _ from 'lodash';


export default function MenuBar(props) {
  const {
    children,
    items,
    activeItem,
    setActiveItem,
    logOutPath
  } = props;

  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [logoutVisible, setLogoutVisible] = React.useState(false);
  const { size } = useWindowDimensions();

  const handleLogout = () => {
    setLogoutVisible(false);
    Cookies.remove('token');
    Cookies.remove('user');
    navigate(logOutPath ? logOutPath : PATH.LOGIN);
  }

  const renderMenuItem = () => {
    return [
      <Menu.Item
        key='admin_menu'
        style={{ padding: '16px', textAlign: 'left' }}
        onClick={() => window.open('/api/admin', '_blank')}>
        <span>
          <Icon name='cogs'/>
          Administrator
        </span>
      </Menu.Item>,
      ...items.map(e => (
        <Menu.Item
          key={`${e.value}_menu`}
          style={{ padding: '16px', textAlign: 'left' }}
          active={e.value === activeItem}
          onClick={() => {
            setActiveItem(e.value);
            setIsMenuVisible(false);
          }}>
            {e.count > 0 && <Label color='orange'>{e.count}</Label>}
            <span style={{ textAlign: 'center' }}>
              {e.icon && <Icon name={e.icon} />}
              {e.text}
            </span>
          </Menu.Item>
      )),
      <Menu.Item
        key='logout_menu'
        style={{ padding: '16px', textAlign: 'left' }}
        onClick={() => setLogoutVisible(true)}>
        <span>
          <Icon name='power'/>
          ออกจากระบบ
        </span>
      </Menu.Item>
    ]
  }

  const renderChildren = () => {
    const activeMenu = items.find(e => e.value === activeItem);
    return (
      <div style={{ padding: '2rem' }}>
        <Header dividing>
          <Icon color='teal' name={_.get(activeMenu, `icon`, '')}/>
          {_.get(activeMenu, `text`, '')}
        </Header>
        {children}
      </div>
    )
  }

  const ToggleMenuLayout = () => {
    return (
      <Grid style={{ height: '100vh', margin: 0 }}>
        <Grid.Row style={{ padding: 0 }} columns='1'>
          <Sidebar.Pushable style={{ padding: 0 }} as={Grid.Column}>
            <Sidebar
              as={Menu}
              animation='overlay'
              direction='left'
              icon='labeled'
              inverted
              onHide={() => setIsMenuVisible(false)}
              vertical
              visible={isMenuVisible}>
              {renderMenuItem()}
            </Sidebar>
            <Sidebar.Pusher dimmed={isMenuVisible}>
              <Menu inverted pointing style={{height: '100%', borderRadius: 0}}>
                <Menu.Item>
                  <Icon size='big' name='bars' onClick={() => setIsMenuVisible(true)}/>
                </Menu.Item>
                <Menu.Item>
                  <Image style={{ width: 50, height: 50 }} centered src={logo}/>
                </Menu.Item>
              </Menu>
              {renderChildren()}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Row>
      </Grid>
    )
  }

  const SideBarLayout = () => {
    return (
      <Grid style={{ height: '100vh', margin: 0 }}>
        <Grid.Row style={{ padding: 0 }} columns='2'>
          <Grid.Column width={size === 'md' ? '4' : '3'} style={{padding: 0}} textAlign='left'>
            <Menu fluid vertical inverted style={{height: '100%', borderRadius: 0}}>
              <Menu.Item>
                <Image src={logo} style={{width: '50%', margin: 'auto', padding: 0 }} />
              </Menu.Item>
              {renderMenuItem()}
            </Menu>
          </Grid.Column>
          <Grid.Column width={size === 'md' ? '12' : '13'}>
            {renderChildren()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <div>
      <Confirm 
        open={logoutVisible}
        content='ต้องการออกจากระบบใช่หรือไม่?'
        cancelButton='ยกเลิก'
        confirmButton='ออกจากระบบ'
        onCancel={() => setLogoutVisible(false)}
        onConfirm={handleLogout}/>
      <Media at='sm'>
        {ToggleMenuLayout()}
      </Media>
      <Media greaterThan='sm'>
        {SideBarLayout()}
      </Media>
    </div>
  )
}

MenuBar.defaultProps = {
  children: null,
  items: [],
  activeItem: '',
  setActiveItem: () => null,
}

MenuBar.propTypes = {
  children: PropTypes.object,
  items: PropTypes.array,
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func,
}