import React from 'react';
import MenuBar from '../components/common/MenuBar';
import { URL_COMMON } from '../constances/urls';
import { GET } from '../frameworks/HttpClient';
import CoinView from '../views/CoinView';
import TechnicianView from '../views/TechnicianView';
import PaymentView from '../views/PaymentView';
import PayingTechnicianView from '../views/PayingTechnicianView';


export default function MainScreen(props) {
  const [activeItem, setActiveItem] = React.useState('technician');
  const [notification, setNotification] = React.useState({
    technician: 0,
    profile: 0,
    payment: 0,
    payment_technician: 0,
    invoice: 0,
    summary: 0,
    report: 0,
  })

  const fetchNotification = async () => {
    try {
      const response = await GET(URL_COMMON.ADMIN_MENU_NOTIFICATION)
      setNotification({...response.data})
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const menus = [
    { value: 'technician', text: 'บัญชีช่าง', icon: 'user', count: notification.technician },
    { value: 'coin', text: 'เหรียญช่าง', icon: 'dot circle', count: notification.coin },
    { value: 'payment', text: 'การชำระเงิน', icon: 'dollar sign', count: notification.payment },
    { value: 'paying_technician', text: 'การชำระเงินช่าง', icon: 'address book outline', count: notification.payment_technician },
    // { value: 'invoice', text: 'ใบกำกับภาษี', icon: 'envelope', count: notification.invoice },
    // { value: 'summary', text: 'สรุปปิดงาน', icon: 'file alternate', count: notification.summary },
    // { value: 'report', text: 'รายงาน', icon: 'chart line', count: notification.report },
  ]

  const renderContext = () => {
    switch(activeItem) {
      case 'technician': return <TechnicianView/>
      case 'coin': return <CoinView/>
      case 'payment': return <PaymentView/>
      case 'paying_technician': return <PayingTechnicianView/>
      default: return null;
    }
  }

  React.useEffect(() => fetchNotification(), [])

  return (
    <div>
      <MenuBar activeItem={activeItem} setActiveItem={setActiveItem} items={menus} >
        {renderContext()}
      </MenuBar>
    </div>
  )
}
