import React from 'react';
import { Button, Divider, Form, Grid, Header, Icon, Image, Label, List, Message, Modal } from 'semantic-ui-react';
import { DefaultServicePaymentModel } from '../../constances/models';
import * as _ from 'lodash';
import { POST } from '../../frameworks/HttpClient';
import { toast } from 'react-toastify';
import { dateTimeToString, formatComma } from '../../frameworks/Util';
import { URL_SERVICE } from '../../constances/urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomErrorMessage from '../common/CustomErrorMessage';
import DetailList from '../common/DetailList';
import CustomFileField from '../common/CustomFileField';


export default function TechnicianPaymentModal(props) {
  const {
    open,
    onClose,
    target
  } = props;

  const [detail, setDetail] = React.useState(DefaultServicePaymentModel);
  const [file, setFile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      image: Yup.mixed().required(),
    }),
    initialValues: { note: '', image: null },
    onSubmit: (data) => doPaid(data)
  })

  const doPaid = async (data) => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const response = await POST(`${URL_SERVICE.PAYMENT_TECHNICIAN}${detail.id}/do_payment/`, data, true);
      toast.success(_.get(response.data, 'detail', 'สำเร็จ'));
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (file) {
      console.log(file.file)
      formik.setFieldValue('image', file.file)
    }
  }, [file])

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null)
    }
  }, [open])

  React.useEffect(() => {
    if(target) {
      setDetail(target)
    }
  }, [target])

  return (
    <>
      <Modal
        centered={false}
        closeIcon
        open={open}
        onClose={onClose}>
        <Modal.Header>
          <Icon color='teal' name='dollar sign'/>
          รายละเอียดการชำระเงิน
        </Modal.Header>
        <Modal.Content>
          <CustomErrorMessage value={errorMessages}/>
            <DetailList
              menus={[
                { icon: 'calendar', title: 'วันที่', content: dateTimeToString(detail.created) },
                { icon: 'file alternate outline', title: 'ประเภท', content: detail.type_display },
                { icon: 'dollar sign', title: 'จำนวนเงิน', content: `${formatComma(detail.amount * (1 - 0.1))} (ยอดเต็มก่อนหัก 10% เป็นเงิน ${formatComma(detail.amount)} บาท)` },
                { icon: 'user outline', title: 'ลูกค้า', content: detail.customer_detail },
                { icon: 'user', title: 'ช่าง', content: detail.technician_detail },
                { icon: 'file alternate outline', title: 'บริการ', content: detail.service_detail },
              ]}/>
          <Form>
            <Divider horizontal>ยืนยันหลักฐานการชำระเงินให้ช่าง</Divider>
            <CustomFileField
              label='หลักฐานการจ่ายเงิน'
              type='file'
              name='image'
              value={formik.values.image ? formik.values.image.name : null}
              error={formik.errors.image}
              onChange={(file) => formik.setFieldValue('image', file)}/>
            <Form.TextArea
              name='note'
              label='หมายเหตุ'
              placeholder='หมายเหตุ...'
              value={formik.values.note}
              error={formik.errors.note}
              onChange={formik.handleChange}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={isLoading}
            onClick={() => onClose()}
            icon='cancel'
            content='ยกเลิก'/>
          <Button
            loading={isLoading}
            onClick={() => formik.handleSubmit()}
            positive
            icon='check'
            content='ชำระเงิน'/>
        </Modal.Actions>
      </Modal>
    </>
  )
}
